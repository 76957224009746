#map {
  width: 100%;
  height: 550px;
}

#map > div {
  border: 1px solid rgba(45, 49, 52, 0.1);
  border-radius: 14px;
}

#map-modal {
  position: absolute;
  top: 10%;
  left: 5%;
  width: 350px;
  height: calc(100vh - 250px);
  padding: 1em;
  z-index: 999999;
  background-color: #fff;
  border: 2px solid #f8f9fa;
  border-radius: 2%;
}
