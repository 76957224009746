.menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 17.625rem;
  padding: 1.09375rem 0 2.1875rem 0;
  background-color: white;
  border: 1px solid rgba(45, 49, 52, 0.1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 0px 10px 10px 0px;
  z-index: 100;

  &__hidden {
    display: none !important;
  }

  &__item {
    color: #6c717c;
    margin-left: 2.1875rem;
    margin-top: 1.875rem;
  }

  &__item > svg {
    fill: rgba(31, 42, 73, 0.5);
    vertical-align: middle;
    margin-right: 1em;
  }

  &__item:hover a,
  &__item:hover svg {
    color: #ef233c;
    fill: #ef233c;
  }

  &__item--active,
  &__item--active svg {
    color: #ef233c;
    fill: #ef233c;
  }
}
