.driver__opts {
  display: flex;
  margin-bottom: 2.5rem;
  justify-content: space-between;
}

.driver__opts > button{
  margin-left: 2.25rem;
}

.driver__filters {
  width: 490px;
  margin-bottom: 2.5rem;
  display: flex;
}