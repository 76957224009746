.ant-modal-content {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  padding: 2.5rem;
  font-family: Poppins;
  text-align: center;
}

.ant-modal-header {
  border-bottom: none;
  border-radius: 1.25rem;
}

.ant-modal-body {
  font-size: 1rem;
  padding: 0;
}
