.ant-dropdown {
  background: #FDFDFD;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  font-weight: 500;
}

.ant-dropdown-menu {
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  border-radius: 10px;
  padding: .5625rem 1.125rem;
}

.statusContainer {
  padding: 10px;
  border-radius: 10px;
  text-align: right;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.statusContainer > div {
  display: flex;
  flex-direction: column;
}