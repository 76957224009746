.input:focus,
.input-text:focus,
.input-password:focus,
.ant-input-wrapper > .ant-input:focus,
.ant-select-selector:focus,
.ant-select-selector:hover,
.ant-input-wrapper > .ant-input:hover,
.ant-select-selector:active,
.ant-picker-focused,
.ant-select-open .ant-select-selector,
.ant-input-affix-wrapper-focused,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
textarea:hover,
textarea:focus {
  border: 0.5px solid #ef233c !important;
  box-shadow: 0 0 0 2px rgba(239, 35, 60, 0.2) !important;
  box-sizing: border-box;
  outline: 0;
}

.ant-picker {
  background: #ffffff;
  border: 1px solid #eaeaea !important;
  box-sizing: border-box;
  border-radius: .625rem !important;
  padding: 0.3rem 1rem;
}

.ant-input-password {
  border-radius: .625rem;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 54px;
  border: 1px solid #eaeaea;
  border-radius: .625rem;
  padding: 12px 0 0 20px;
}
.ant-select-selection-search {
  padding: 12px;
}
.ant-input-group-addon {
  background: none;
}
/* .ant-input-search-button {
  height: 54px !important;
}
.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  border-radius: 0 13px 13px 0;
  border-left-color: #ef233c;
} */

/* .ant-form-item {
  margin: 0;
} */

.ant-radio-checked .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  border-color: #ef233c;
}
.ant-radio-inner::after {
  background-color: #ef233c;
}
.ant-radio-checked::after {
  border: 1px solid #ef233c;
}

/* Overwriting antd table style */
.ant-table-tbody {
  box-shadow: 0px 1px 4px rgb(0 0 0 / 5%);
}
.ant-table-thead > tr > th {
  background-color: #f3f3f3;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: .625rem;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: .625rem;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #ef233c;
  color: #ef233c;
}
.ant-pagination-item-link,
.ant-pagination-item {
  border-radius: .625rem !important;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover,
.ant-pagination-item-active,
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #ef233c;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a,
.ant-pagination-item-active a,
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #ef233c;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #ef233c;
  background: #fff;
  border-color: #ef233c;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover {
  color: #ef233c;
}
.ant-tabs-ink-bar,
.ant-picker-range .ant-picker-active-bar{
  background-color: #ef233c;
}

.ant-spin.ant-spin-spinning > span > svg {
  fill: #ef233c;
}

.ant-switch-checked {
  background-color: #ef233c;
}

.ant-switch-checked:focus,
.ant-radio-input:focus+.ant-radio-inner {
  box-shadow: 0 0 0 2px rgba(255, 24, 24, 0.2);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ffe6e8;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 1px solid #ef233c !important;
  box-shadow: 0 0 0 2px rgba(239, 35, 60, 0.2) !important;
}

.ant-btn-primary {
  background: #ef233c;
  border: 1px solid #ef233c;
}

.ant-drawer-header{
  justify-content: start;
}

.ant-drawer-header-title {
  flex: 0;
}

h5.ant-typography, .ant-typography h5 {
  margin: 0;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #EF233C;
  border-color: #EF233C !important;
}

.ant-checkbox-checked:after,
.ant-checkbox-input:focus+.ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
  border: 1px solid #EF233C;
}