.map {
  height: 100%;
  border: 1px solid rgba(45, 49, 52, 0.2);
  border-radius: 10px;
}

.orderSmallCard {
  height: 89px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  padding: 1rem;
  margin-bottom: 1rem;
  user-select: none;
  cursor: pointer;
  background-color: #fff;
}

.orderSmallCard--selected {
  border: 2px solid #ef233c;
}

.orderTime {
  color: #c8c8c8;
  font-weight: 600;
}

.orderId {
  color: #ef233c;
  font-weight: 600;
  font-size: 14px;
}

.orderId--detail {
  font-size: 24px;
}

.orderId:hover,
.cardLink:hover {
  cursor: pointer;
  text-decoration: underline;
}

.mapContainer {
  width: 100%;
}

.orderFeedContainer {
  height: 85%;
}

.orderFeedContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
}

.orderFeedContainer::-webkit-scrollbar {
  width: 6px;
  background-color: #fff;
}

.orderFeedContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(121, 119, 119);
}

.filter-button {
  border: 1px solid #eaeaea;
  border-radius: 6px;
}

.marker {
  background-repeat: no-repeat;
  background-size: 100%;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  cursor: pointer;
}
.marker--partner {
  background-image: url("../../assets/icons/orders/partner-marker.svg");
  width: 1.625rem;
  height: 1.625rem;
}
.marker--received {
  background-image: url("../../assets/icons/orders/default-marker.svg");
}
.marker--readyToDelivery {
  background-image: url("../../assets/icons/orders/default-marker.svg");
}
.marker--unassigned {
  background-image: url("../../assets/icons/orders/unassigned-marker.svg");
}
.marker--assigned {
  background-image: url("../../assets/icons/orders/assigned-marker.svg");
}
.marker--inTransit {
  background-image: url("../../assets/icons/orders/intransit-marker.svg");
}
.marker--concluded {
  background-image: url("../../assets/icons/orders/concluded-marker.svg");
}
.marker--cancelled {
  background-image: url("../../assets/icons/orders/cancelled-marker.svg");
}
.marker--selected {
  box-sizing: content-box;
  border: 4px solid #ef233c;
}

.driverMarker--inTransit {
  width: 30px;
  height: 30px;
  background-image: url("../../assets/icons/orders/intransit-driver-marker.svg");
}

.driverMarker--returning {
  width: 30px;
  height: 30px;
  background-image: url("../../assets/icons/orders/returning-driver-marker.svg");
}

.driversInfo-button {
  position: absolute;
  cursor: pointer;
  right: 20px;
  bottom: 30px;
  border-radius: 50px;
}

.driverInfo-list {
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
  margin-top: 1.625rem;
  padding-left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.driverInfo-item-list {
  list-style-position: inside;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  padding: 1rem 0;
}
