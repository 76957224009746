.content {
  width: 42.5rem;
}

.formContainer {
  margin-top: 5rem;
  padding: 0 6.875rem;
  display: flex;
  flex-direction: column;
}
