h1, h2, h3, h4, h5 {
  margin: 0px;
  padding: 0px;
}

body {
  background-color: #FDFDFD;
}

.App {
  font-family: Poppins;
}

p, figure {
  margin: 0;
  padding: 0;
}

.input,
.input-text,
.input-password,
textarea {
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaeaea !important;
  border-radius: .625rem !important;
  padding: 0.9375rem !important;
  box-sizing: border-box;
  resize: none;
}

input:disabled {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
}

svg,
img {
  display: inline;
  vertical-align: initial;
}

button > svg {
  vertical-align: middle;
}

.text-green {
  color: #74de4f;
}
.text-red,
a:hover {
  color: #ef233c;
}
.bold {
  font-weight: 600;
}

.mapboxgl-popup-close-button {
  width: 25px;
  height: 25px;
}

.bordered-icon {
  border-radius: 8px;
}

.exportButton {
  background: #EF233C;
  color: #FFF;
  border: 1px solid #ef233c;
  border-radius: 14px;
  font-weight: 500;
  height: 3.375rem;
  padding: 0.9375rem 1.5rem;
  cursor: pointer;
}

.exportButton:hover,
.exportButton:focus,
.exportButton:active {
    color: #FFF;
}

.mapboxgl-popup-content {
  box-shadow: 3px 5px 5px rgb(0 0 0 / 25%);
}